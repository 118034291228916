<script>

export default {
  name: "RateCardDeliveryPrice",

  props: {
    detailInfo: {
      type: Array,
      required: true
    },
    
    totalPrice: {
      type: Number,
      required: true
    },

    isAuth: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    sortServiceElems() {
      return [...this.detailInfo].sort((s1, s2) =>
        s1.service_id > s2.service_id ? 1 : -1
      );
    },
  },

  methods: {
    getNumber(value) {
      return parseFloat(value).toLocaleString("ru-RU");
    },
  },
};
</script>

<template>
  <div class="transit-price" :class="{ 'not-auth' : !isAuth }">
    <div
      class="transit-price__row"
      :class="sortServiceElems.length <= 2 ? 'transit-price__row--two-elems' : '' "
      v-if="isAuth"
      >
      <div class="transit-price__column"
        v-for="(service, index) in sortServiceElems"
        :key="index">
        <p class="transit-price__title transit-price__title--usd" v-if="service.cur_code === 'USD'">
          {{ getNumber(service.summa) }} $
        </p>
        <p  class="transit-price__title transit-price__title--ru">
          {{ getNumber(service.cost) }} ₽
        </p>
        <p class="transit-price__title transit-price__title--way">
          {{ service.service }}
        </p>
      </div>
    </div>
    <div class="transit-price__total-amout" :class="{ 'not-auth' : !isAuth }">
      <span>Итого</span>
      {{ getNumber(totalPrice) }} ₽
    </div>
  </div>
</template>

<style scoped lang="scss">
.transit-price {
  display: flex;
  flex-direction: column;

  border-radius: 12px;
  background-color: var(--blue-10);
  padding: 27px 24px 22px;

  position: relative;
  width: 100%;
  height: 100%;

  &.not-auth {
    background: none;
  }

  @media (max-width: $mobile-width) {
    padding: 16px;
  }

  &__row {
    display: flex;
    gap: 0 60px;
    justify-content: flex-start;
    align-items: flex-end;

    padding-bottom: 16px;
    border-bottom: 1px solid var(--grey-50);

    @media (max-width: 1368px) {
      justify-content: space-between;
    }

    @media (max-width: 400px) {
      gap: 0 35px;
    }

    &--two-elems {
      @media (max-width: $tablet-width) {
        position: relative;

        .transit-price__column {
          position: static;

          &:not(:first-child):before {
            width: 100%;
            right: 0;
          }
        }
      }
    }
  }

  &__column {
    position: relative;
    display: flex;
    flex-direction: column;
    width: max-content;

    &:not(:first-child):before {
      content: "+";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 16px;
      font-weight: 700;
      right: 100%;
      width: 58px;
      text-align: center;

      @media (max-width: $tablet-width) {
        width: 168px;
      }

      @media (max-width: 500px) {
        width: 50px;
      }

      @media (max-width: 400px) {
        width: 35px;
      }
    }
  }

  &__title {
    letter-spacing: 0.14px;
    color: var(--grey-70);

    @include font-description-0;

    &--ru {
      @include font-body-2;
      font-weight: 600;
      //font-size: 16px;
      color: var(--grey-100);
      margin: 3px 0 2px;
      letter-spacing: 0.16px;
    }

    &--way {
      font-size: 12px;
      letter-spacing: 0.12px;
    }
  }

  &__total-amout {
    @include font-description-semibold-0;

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 0 10px;
    height: max-content;

    color: var(--grey-100);
    font-size: 28px;
    letter-spacing: 0.28px;

    padding-top: 17px;

    &.not-auth {
      padding-top: 0
    }

    @media (max-width: $mobile-width) {
      font-size: 24px;
      color: var(--blue-70);
    }

    > span {
      color: var(--grey-70);
      font-size: 12px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.12px;
      height: 12px;
    }
  }
}

</style>
