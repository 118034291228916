<template>
  <div>
    <v-autocomplete
      v-model="ids"
      solo
      flat
      class="cvtb-autocomplete"
      color="var(--main-orange)"
      :label="element.caption"
      :item-text="fieldName"
      :item-value="element.keyfield"
      :items="list"
      :chips="element.chips"
      :clearable="element.chips"
      :deletable-chips="element.chips"
      :small-chips="element.chips"
      :multiple="element.multiple"
      :search-input.sync="search"
      @change="onChange(element.name)"
      @focus="isFocused = true"
      @blur="isFocused = false"
      @input="search = ''"
    >
      <template v-slot:selection="data" v-if="element.chips">
        <v-chip
            v-bind="data.attrs"
            v-if="isFocused || data.index < 3"
            :key="data.item.id"
            small
            close
            @click:close="removeItem(data.item)"
        >
          {{ element.name === 'place_from' ? data.item.alt_name : data.item.name }}
        </v-chip>
        <v-chip
            v-if="!isFocused && data.index === 3"
            @click:close="removeItem(data.item)"
            small
        >
          +{{ ids.length - 3 }}
        </v-chip>
      </template>

    </v-autocomplete>
  </div>
</template>

<script>
export default {
  name: "CVTBAutoComplete",
  props: {
    element: {
      type: [Object, String],
    },
    val:{
      type: Array,
    }
  },

  components: {},

  data: () => ({
    el: {},
    ids: [],
    items: [],
    result: {},
    list: [],
    isFocused: false,
    lang: 'en',
    fieldName: 'name',

    search: null,
  }),

  watch: {
    val: {
      handler(){
        if (this.element.multiple){
          for (let i = 0; i < this.val.length; i++) {
            this.ids.push(this.val[i])
          }
        } else  {
          this.ids = this.val[0]
        }
      },
      deep: true
    },

    search(str) {
      if (!str) return
      this.checkLang(str)
    },
  },

  mounted() {
    this.getReferenceList(this.element.reference)
  },

  methods: {
    getReferenceList(refName) {
      this.$postapi(this.$address + this.$getters.getReferenceData.uri, {
        method: this.$getters.getReferenceData.name,
        reference_name: this.$references[refName].name
      }).then(data => {
        if (data.error == 0) {
          this.list = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`))
        } else {
          return []
        }
      });
    },

    removeItem(item) {
      console.log(item)
      this.ids = this.ids.filter(i => i !== item.id);
    },

    onChange(name) {
      this.getResultItems()
      this.result = {element: name, value: this.items}
      this.$emit("selected", this.result)
    },

    getResultItems() {
      this.items = []
      for (let i = 0; i < this.list.length; i++) {
        if (this.element.multiple){
          if (this.ids.includes(this.list[i][this.element.keyfield]))
            this.items.push(this.list[i])
        } else {
          if (this.ids == this.list[i][this.element.keyfield])
            this.items.push(this.list[i])
        }
      }
    },

    checkLang(str) {
      if (str.length > 0) {
        if (/[а-я]/i.test(str) == true) {
          this.lang = "ru"
          if(this.element.name === 'place_from'){
            this.fieldName = "alt_name"
          }
        } else {
          this.lang = "en"
          this.fieldName = "name"
        }
      }
      this.search = str
    },
  },
};
</script>

<style scoped>
.cvtb-autocomplete {
  border-radius: 2px;
  display: flex;
}
</style>
