<script>
import AppDropDown from '../ui/AppDropDown.vue';

import {bus} from "@/router/bus";

export default {
  name: 'AlternativeRoute',

  components: {
    AppDropDown
  },
  
  props: {
    isDirectAlternative: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  data() {
    return {
      place_from: [],
      place_to: [],
      places_to: [],
      on_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      alt_places_from:[],
      alt_places_to:[],
      alt_place_from: [],
      place_to_name: '',
      place_from_name: '',
      unit_code: [],
      client_id: null,
      alarm_class: [],
      alarm_classes: [],
      method: '',
      type: 0,

      altRoute: [],
      selectArr: [],
    }
  },

  mounted() {
    bus.$on("getalternativeroute", (data) => {
      this.selectArr = []
      this.setSendingData(data);
    });
    this.cid = this.$_getsetting('client_id') ? this.$_getsetting('client_id') : -99;
  },

  methods: {
    setSendingData(data) {
      this.place_from_name = data.place_from_name
      this.place_to_name = data.place_to_name
      this.type = data.type
      this.method = data.method;
      let out_date = new Date(data.on_date);
      out_date.setDate(out_date.getDate());
      var sending_data = {}
      sending_data = {
        place_from: data.place_from,
        place_to: data.place_to,
        type: 1,
        method: this.$getters.getAlternativeRoute.name,
      }
      if (data.is_alt_to == 1) {
        this.getAlternativeRoute(sending_data, data)
      }
      sending_data = {
        place_from: data.place_from,
        place_to: data.place_to,
        type: 0,
        method: this.$getters.getAlternativeRoute.name,
      }
      this.getAlternativeRoute(sending_data, data)
    },
  
    getAlternativeRoute(sending_data, data) {
      if (data.method == 'ratelist') {
        this.$postapi(this.$address + this.$getters.getAlternativeRoute.uri, sending_data).then(data => {
          if (data.error == 0) {
            if (sending_data.type == 0) {
              this.alt_places_from = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`))
              bus.$emit('currentaltroutes', this.alt_places_from)
              this.getNewPlacesArr(sending_data.type)
            } else {
              this.alt_places_to = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`))
              this.getNewPlacesArr(sending_data.type)
            }
            bus.$emit('get_next_period')
          } else {
            bus.$emit("show_notify", {
              color: "error",
              notifytext: data.message
            });
          }
        });
      } else {
        this.$postapi(this.$address + this.$getters.getAlternativeRoute.uri, {
          method: this.$getters.getAlternativeRoute.name,
          place_to: data.place_to,
          place_from: data.place_from,
          unit_code: data.unit_code,
          client_id: data.client_id != null ? data.client_id : -99,
          alarm_class: data.alarm_class,
          type: sending_data.type,
        }).then(data => {
          if (data.error == 0) {
            if (sending_data.type == 0) {
              this.alt_places_from = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`))
            } else {
              this.alt_places_to = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`))
            }
            
            bus.$emit('get_next_period')
          } else {
            bus.$emit("show_notify", {
              color: "error",
              notifytext: data.message
            });
          }
        });
        this.alarm_class = data.alarm_class;
      }
      this.place_to = data.place_to;
      this.place_from = data.place_from;
      this.on_date = data.on_date;
      this.unit_code = data.unit_code;
      this.client_id = data.client_id != null ? data.client_id : -99 ;
    },

    searchAlternativeRoute(item){
      if(item.type == 0){
        this.place_from = item.id
        this.place_from_name = item.placeFrom
        setTimeout(this.sendRequest(), 500)
        bus.$emit('changeplacefrom', this.place_from)
        bus.$emit('changealertplacefrom', this.place_from)
      }else if(item.type == 1){
        this.place_to = item.id
        this.place_to_name = item.placeTo
        setTimeout(this.sendRequest(), 500)
        bus.$emit('changeplaceto', this.place_to)
        bus.$emit('changealertplaceto', this.place_to)
      }
    },

    sendRequest() {
      if (this.method == 'ratelist') {
        bus.$emit("getratelistAlternative", {
          place_from: this.place_from,
          place_to: this.place_to,
          unit_code: this.unit_code,
          on_date: this.on_date,
          place_to_name: this.place_to_name,
          place_from_name: this.place_from_name,
          client_id: this.$_getsetting('client_id') ? this.$_getsetting('client_id') : -99,
        });
      } else {
        bus.$emit("getratealarmlist", {
          place_from: this.place_from,
          place_to: this.place_to,
          unit_code: this.unit_code,
          on_date: this.on_date,
          alarm_class: this.alarm_class,
          place_to_name: this.place_to_name,
          place_from_name: this.place_from_name,
          client_id: this.$_getsetting('client_id') ? this.$_getsetting('client_id') : -99,
        });
      }
    },

    checkParameters(data){
      return (data.on_date != null && data.place_to.length > 0 && data.place_from.length > 0 && data.unit_code.length > 0 && data.client_id != null) ? true : false
    },

    getNewPlacesArr(type){
      if(type == 0){
        this.selectArr.push(...this.alt_places_from.map(place => {
          if((this.isDirectAlternative == true && place.is_direct == 1)
          || (place.is_direct == 0 && this.isDirectAlternative == false)){
            return {
              route: `${place.name} - ${this.place_to_name}`,
              id: place.id,
              placeFrom: place.name,
              type: type
            }
          }
        }))
      }else if(type == 1){
        this.selectArr.push(...this.alt_places_to.map(place => {
          if((this.isDirectAlternative == true && place.is_direct == 1)
          || (place.is_direct == 0 && this.isDirectAlternative == false)){
            return {
              route: `${this.place_from_name} - ${place.name}`,
              id: place.id,
              placeTo: place.name,
              type: type
            }
          }
        }))
      }
      this.filteredSelectArr()
    },

    filteredSelectArr(){
      this.selectArr = this.selectArr.filter(item => item !== undefined) 
      this.selectArr = this.selectArr.filter((item, idx, arr) => idx === arr.findIndex((t) => t.id === item.id))
    },
  },
}
</script>

<template>
  <div class="alternative-route">
    <div class="alternative-route-info" v-if="selectArr.length > 0">
     <div class="alternative-route__select">
      <AppDropDown
        class="alternative-route__select-menu"
        v-model="altRoute"
        label="Альтернативные маршруты"
        item-text="route"
        :options="selectArr"
        placeholder="Выберите маршрут"
        @input="searchAlternativeRoute"
        return-object
      />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.alternative-route{
  @media screen and (max-width: 1440px){
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  @media (max-width: $mobile-width) {
    grid-column: 1 / 3;
  }
}
.alternative-route-info-title{
  color: var(--grey-70);
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 4px;
}

.alternative-route__select{
  &-menu{
    color: var(--grey-70);
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 500;
  }
}


</style>
