<script>

export default {
  name: "RateCardDeliveryDates",

  props: {
    rateAllTIme: {
      type: Number,
      required: true
    },

    seaStartDates:{
      type: [Array, String],
      required: true
    },

    isAuth: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    windowSize() {
      return document.documentElement.clientWidth;
    },
  },

  methods: {
    getArrivalDate(date) {
      const originalDate = new Date(date);
      const arrivalDate = new Date(
        originalDate.setDate(originalDate.getDate() + this.rateAllTIme)
      );
      return this.getDateTitle(arrivalDate);
    },

    getDateTitle(date) {
      return this.windowSize > 744
        ? new Date(date).toLocaleDateString("ru", {
            month: "long",
            day: "numeric",
          })
        : new Date(date).toLocaleDateString("ru", {
            month: "short",
            day: "numeric",
          });
    },

    getSortedDates() {
      if (this.seaStartDates) {
        if (this.seaStartDates.length > 0) {
          if (this.seaStartDates[0].length > 0) {
            return this.windowSize > 743
              ? this.seaStartDates.slice(0, 3)
              : this.seaStartDates.slice(0, 2);
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      }
    },
  },
};
</script>

<template>
  <div class="transit-date">
    <div class="transit-date--desktop" v-if="windowSize > 744">
      <div class="transit-date__row"
        v-if="getSortedDates() !== 0 && seaStartDates"
        :class="{ 'not-auth' : !isAuth }"
        >
        <div class="transit-date__column transit-date__column--titles">
          <p class="transit-date__title">Ближайшие выходы:</p>
          <p class="transit-date__title" v-if="isAuth && rateAllTIme < 9999">
            Плановое прибытие на склад:
          </p>
        </div>
        <div
          v-for="(date, index) in getSortedDates()"
          class="transit-date__column"
          :class="{ 'not-auth' : !isAuth || rateAllTIme >= 9999 }"
          :key="index">
          <p class="transit-date__arrive-title">
            {{ getDateTitle(date) }}
          </p>
          <p class="transit-date__shipping-title" v-if="isAuth && rateAllTIme < 9999">
            {{ getArrivalDate(date) }}
          </p>
        </div>
      </div>
      <div class="transit-date__row transit-date__title" v-else>
        Данные по выходам отсутствуют
      </div>
    </div>
    <div class="transit-date--mobile" v-else>
      <div class="transit-date__row"
        v-if="getSortedDates() !== 0 && seaStartDates">
        <div class="transit-date__column transit-date__column--titles">
          <p class="transit-date__title">Ближайшие выходы:</p>
        </div>
        <div
          v-for="(date, index) in getSortedDates()"
          class="transit-date__column"
          :key="index">
          <p class="transit-date__arrive-title">
            {{ getDateTitle(date) }}
          </p>
        </div>
      </div>
      <div class="transit-date__row transit-date__title" v-else>
        Данные по выходам отсутствуют
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.transit-date {
  width: 100%;
  height: 100%;

  &--desktop {
    height: 100%;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    //align-items: center;
    gap: 0 16px;

    padding: 30px 20px 43px;
    background-color: var(--grey-5);
    border-radius: 12px;

    height: 100%;
    width: 100%;

    &.not-auth {
      padding-bottom: 30px;
    }

    @media (max-width: $tablet-width) {
      justify-content: flex-start;
      gap: 0 40px;
    }

    @media (max-width: $mobile-width) {
      padding: 16px 35px 21px 16px;
      gap: 0 34px;
    }

    
  }
  &__column {
      position: relative;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      gap: 38px 0;
      text-align: center;
      height: 100%;

      @media (max-width: $mobile-width) {
        align-items: center;
        justify-content: center;
      }

      &:not(:first-child):before {
        position: absolute;
        display: block;
        content: "";
        background: url("../../assets/img/icons/vector-arrive.svg") no-repeat;

        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        margin: auto;
        height: 6px;
        width: 13px;
        background-size: contain;

        @media (max-width: $mobile-width) {
          content: unset;
        }
      }

      &.not-auth {
          &:not(:first-child):before {
            background: none;
          }
        }

      p {
        @include font-subtitle-medium;
      }

      &--titles {
        max-width: 120px;
        text-align: left;

        @media (max-width: $mobile-width) {
          max-width: 70px;
        }

        p {
          color: var(--grey-70);

          @include font-description-1;
        }
      }
    }

    &__shipping-title {
      margin-bottom: 8px;
      color: var(--grey-70);

      &:not(:first-child) {
        white-space: pre;
      }
    }

    &__arrive-title {
      color: var(--grey-100);
      font-weight: 600 !important;
    }

}
</style>
